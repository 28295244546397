import { useLocation } from '@solidjs/router'
import { Component, Show } from 'solid-js'
import styles from '~/components/footer/footer.module.scss'
import LanguageSelection from '~/components/language-selection/language-selection'
import { translations } from '~/translations'

const Footer: Component = () => {
  const location = useLocation()
  const footerPages = [
    '/account/login',
    '/account/register',
    '/account/request-password-reset',
    '/account/reset-password',
    '/account/subscriptions',
    '/account/subscribe',
    '/account/subscribe/pro',
    '/documentation/translation-import',
    '/series',
    '/account',
  ]
  const footerLanguage = [
    '/account/login',
    '/account/register',
    '/account/request-password-reset',
    '/account/reset-password',
  ]
  const showFooter = () => footerPages.includes(location.pathname) || location.pathname === '/'
  const showLanguage = () => footerLanguage.includes(location.pathname) || location.pathname === '/'

  return (
    <Show when={showFooter()}>
      <div class={styles.footer}>
        <div>
          © {new Date().getFullYear()} Zoon - <a href='https://blackstudio.fr/' target='blank'>Blackstudio</a>
        </div>
        {showLanguage() && (
          <div>
            <LanguageSelection small />
          </div>
        )}
        <div>
          <a
            href='https://zoon-editor.com/conditions-generales-de-vente/'
            target='blank'
          >
            {translations().footer.legal}
          </a>
        </div>
      </div>
    </Show>
  )
}

export default Footer
